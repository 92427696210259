import { useDispatch } from 'react-redux';
import { EnergyStarIconBlue } from '../Icons/EnergyStarIconBlue';
import Button from '../mantine/Button';
import { MainContainer } from './OnboardingModal';
import ModalCustom from '../mantine/Modal';
import { actions as modalActions } from '../../ducks/modal';

import styled from 'styled-components';

const TopHeading = styled.div`
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;
`;

const HeadingText = styled.div`
  color: #162447;
  font-size: 22px;
  font-weight: 700;
  margin: 20px 0px;
`;

const EnergyStarIconWrapper = styled.div`
  svg {
    scale: 0.8;
  }
`;

const SubHeadingText = styled.p`
  margin: 10px;
`;

const ButtonContainer = styled.div`
  margin: 50px 0px;
  display: flex;
  gap: 20px;
`;

const EnableSitesModal = () => {
  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(modalActions.hideModal());
    sessionStorage.setItem('userDismissedEnableEnergyStarPopup', 'true');
  };

  return (
    <ModalCustom opened onClose={onClose} size='xl' closeOnClickOutside={false}>
      <MainContainer>
        <TopHeading>Welcome to Redaptive ONE</TopHeading>
        <EnergyStarIconWrapper>
          <EnergyStarIconBlue />
        </EnergyStarIconWrapper>
        <HeadingText>
          Start enabling sites to auto-sync with Energy Star Portfolio Manager
        </HeadingText>
        <SubHeadingText>
          You have properties eligible for submissions.
        </SubHeadingText>

        <ButtonContainer>
          <Button
            size='sm'
            variant='outline'
            onClick={onClose}
            gainsightTagId={`start-onboarding-not-now-button`}
          >
            Not Now
          </Button>
          <Button
            size='sm'
            onClick={() =>
              dispatch(modalActions.showOnboardingEnergyStarModal())
            }
            gainsightTagId={`start-onboarding-enable-sites-button`}
          >
            Enable Sites
          </Button>
        </ButtonContainer>
      </MainContainer>
    </ModalCustom>
  );
};

export default EnableSitesModal;
